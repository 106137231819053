import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { aiGenerateCardText, aiGenerateCardWithImages, CardText, GetCardTextPaginatedRequest, GetCardTextSearchParams } from '../common/card-model';

import { 
  createCardTextService, 
  CreateCardTextWithFileRequest, 
  createCardTextWithFileService, 
  deleteCardTextService, 
  editCardTextService, 
  getCardsTextByCategoryPaginatedService, 
  getCardsTextByCategoryService, 
  getCardsTextTagsService, 
  getCardsTextWithFiltersService, 
  getCardTextDetailsService 
} from '../services/cardTextAxiosServices';
import { 
  guestCardTextService, 
  guestTypingCardTextService, 
  incorrectGuestCardTextService, 
  incorrectGuestTypingCardTextService 
} from '../services/cardTextVoteAxiosServices';
import { aiCreateCardTextProps, aiCreateCardTextService, generateCardsTextService, generateCardsWithImagesService } from '../services/generateCardAxiosServices';
import { RootState } from './store';

interface CardsTextStateType {
    allCardsText: CardText[];
    counts: number | null;
    pagesCardText: number;
    createCardsTextLoading: boolean;
    createCardsTextError: string | undefined;
    filteredCardsText: CardText[];
    cardTextDetails: CardText;
    categoryTags: string [];
    loadingGetCardText: boolean;
    loadingFetchAllCardsText: boolean;
    loadingCreateCardText: boolean;
    loadingDeleteCardText: boolean;
    loadingCardTexTags: boolean;
    loadingGeneratedCardsText: boolean;
    loadingAiCreateCardsText: boolean;
    generatedCardsText: Pick<CardText, 'id' | 'sideOne' | 'sideTwo' | 'tag' | 'categoryId'>[];
    errorGenerateCardsText: string | undefined;
    errorCreateAiCardsText: string | undefined;
    errorDeleteCardText: string | undefined;
    errorCreateCardText: string | undefined;
    errorUpdateCardText: string | undefined;
    errorCardTextTags: string | undefined;
    errorGetCardText: string | undefined;
    loadingUpdateCardText: boolean;
    loginRequired: boolean; 
    randomId: number;
    usedIds: number [];
    guestOrNot: number;
}

const initialState: CardsTextStateType = {
    allCardsText: [],
    counts: null,
    pagesCardText: 0,
    filteredCardsText: [],
    createCardsTextLoading: false,
    createCardsTextError: undefined,
    cardTextDetails: {
      id: "",
      sideOne: "",
      sideTwo: "",
      categoryId: "",
      tag: "",
      createdAt: "",
      updateAt: "",
      guessesScore: 0,
      incorrectGuessesScore: 0,
      guessesScoreTyping: 0,
      incorrectGuessesScoreTyping: 0
    },
    categoryTags: [],
    loadingGetCardText: false,
    loadingFetchAllCardsText: false,
    loadingCreateCardText: false,
    loadingDeleteCardText: false,
    loadingUpdateCardText: false,
    loadingCardTexTags: false,
    loadingGeneratedCardsText: false,
    loadingAiCreateCardsText: false,
    generatedCardsText: [{
      id: "",
      sideOne: "",
      sideTwo: "",
      categoryId: "",
      tag: "",
    }],
    errorGenerateCardsText: undefined,
    errorCreateAiCardsText: undefined,
    errorDeleteCardText: undefined,
    errorCreateCardText: undefined,
    errorUpdateCardText: undefined,
    errorCardTextTags: undefined,
    errorGetCardText: undefined,
    loginRequired: false,
    randomId: 0,
    usedIds: [],
    guestOrNot: 0
}

export const getCardTextTags = createAsyncThunk('cardText/getCardTextTags', async (categoryId: string) => {
  let response
  try {
    response = await getCardsTextTagsService(categoryId)
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const getAllCardsTextByFilters = createAsyncThunk('cardText/getAllCardsTextByFilters', async (
  {categoryId, quantity, tags, type} :GetCardTextSearchParams
) => {
  let response
  try {
    response = await getCardsTextWithFiltersService({categoryId, quantity, tags, type})
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const getAllCardsTextByCategory = createAsyncThunk('cardText/getAllCardsTextByCategory', async (
  categoryId: string
) => {
  let response
  try {
    response = await getCardsTextByCategoryService(categoryId)
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const getAllCardsTextByCategoryPaginated = createAsyncThunk('cardText/getAllCardsTextByCategoryPaginated', async ({
  categoryId,
  currentPage,
  searchQuery,
  tags,
}: GetCardTextPaginatedRequest) => {
  let response
  try {
    response = await getCardsTextByCategoryPaginatedService(categoryId, currentPage, tags, searchQuery)
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const createNewCardText = createAsyncThunk('cardText/createNewCardText', async ({
  sideOne,
  sideTwo, 
  tag, 
  categoryId
}:Pick<CardText, 'sideOne' | 'sideTwo' | 'tag' | 'categoryId'> ) => {
  let response;
  try {
    response = await createCardTextService({sideOne, sideTwo, tag, categoryId})
  } catch(error) {
    console.error(error)
    throw error
  }

  return response?.data 

})

export const createNewCardsTest = createAsyncThunk('cardPicture/createNewCardsTest', async ({
  fileForCards,
  categoryId
}: CreateCardTextWithFileRequest) => {
  let response;
  try {
    response = await createCardTextWithFileService({fileForCards, categoryId})
  } catch (error: any) {
    if(error.response && error.response.data.error) {
      throw error.response.data.error
    } else {
      throw error
    }
  }
  return response?.data
})

export const deleteCardTextById = createAsyncThunk('cardText/deleteCardText', async(cardId: string) => {
  try {
    await deleteCardTextService(cardId)
  } catch(error) {
    console.error(error)
    throw error
  }
})

export const editCardTextById = createAsyncThunk('cardText/editCardText', async({
  sideOne, 
  sideTwo, 
  categoryId, 
  tag, 
  id
}: Pick<CardText, "sideOne" | "sideTwo" | "categoryId" | "id" | "tag">) => {
  try {
    await editCardTextService({sideOne, sideTwo, categoryId, tag, id})
  } catch(error) {
    console.error(error)
    throw error
  }
})

export const aiCreateCardTextBulk = createAsyncThunk('cardText/aiCreateCardTextBulk', async({
  categoryName, 
  flashcards
}: aiCreateCardTextProps) => {
  try {
    await aiCreateCardTextService({categoryName, flashcards})
  } catch(error) {
    console.error(error)
    throw error
  }
})

export const aiGenerateCardTextById = createAsyncThunk('cardText/aiGenerateCardTextById', async({
  topic,
  sideOne,
  sideTwo,
  level,
  audienceAge,
  language,
  cardNumber,
}: aiGenerateCardText) => {
  let response
  try {
    response = await generateCardsTextService({topic, sideOne, sideTwo, level, audienceAge, language, cardNumber })
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const aiGenerateCardsWithImages = createAsyncThunk('cardText/aiGenerateCardsWithImages', async({
  firstImage,
  secondImage,
  thirdImage,
  imageLanguage,
  cardQuantity,
  language
}: aiGenerateCardWithImages) => {
  let response
  try {
    response = await generateCardsWithImagesService({firstImage, secondImage, thirdImage, imageLanguage, cardQuantity, language })
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const getCardTextById = createAsyncThunk('cardText/getCardTextById', async(cardId: string) => {
  let response;
  try {
    response = await getCardTextDetailsService(cardId)
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const guestCardText = createAsyncThunk('cardText/guestCardText', async({
  id,
  categoryId,
  sideOne,
  sideTwo,
  guessesScore
}: Pick<CardText, "id" | "categoryId" | "sideOne" | "sideTwo" | "guessesScore">) => {
  let response;
  try {
    response = await guestCardTextService({
      id,
      categoryId,
      sideOne,
      sideTwo,
      guessesScore: guessesScore + 1
    })
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const notGuestCardText = createAsyncThunk('cardText/notGuestCardText', async({
  id,
  categoryId,
  sideOne,
  sideTwo,
  incorrectGuessesScore
}: Pick<CardText, "id" | "categoryId" | "sideOne" | "sideTwo" | "incorrectGuessesScore">) => {
  let response;
  try {
    response = await incorrectGuestCardTextService({
      id,
      categoryId,
      sideOne,
      sideTwo,
      incorrectGuessesScore: incorrectGuessesScore + 1
    })
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const guestTypingCardText = createAsyncThunk('cardText/guestTypingCardText', async({
  id,
  categoryId,
  sideOne,
  sideTwo,
  guessesScoreTyping
}: Pick<CardText, "id" | "categoryId" | "sideOne" | "sideTwo" | "guessesScoreTyping">) => {
  let response;
  try {
    response = await guestTypingCardTextService({
      id,
      categoryId,
      sideOne,
      sideTwo,
      guessesScoreTyping: guessesScoreTyping + 1
    })
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})

export const notGuestTypingCardText = createAsyncThunk('cardText/notGuestTypingCardText', async({
  id,
  categoryId,
  sideOne,
  sideTwo,
  incorrectGuessesScoreTyping
}: Pick<CardText, "id" | "categoryId" | "sideOne" | "sideTwo" | "incorrectGuessesScoreTyping">) => {
  let response;
  try {
    response = await incorrectGuestTypingCardTextService({
      id,
      categoryId,
      sideOne,
      sideTwo,
      incorrectGuessesScoreTyping: incorrectGuessesScoreTyping + 1
    })
  } catch(error) {
    console.error(error)
    throw error
  }
  return response?.data
})


export const cardsTextSlice = createSlice({
    name: 'cardText',
    initialState,
    reducers: {
      cleanCreateError: (state: CardsTextStateType) => {
        state.errorCreateCardText = undefined
        state.errorGetCardText = undefined
        state.errorUpdateCardText = undefined
      },
      cleanDeleteError: (state: CardsTextStateType) => {
        state.errorDeleteCardText = undefined
      },
      cleanCreateCardsError: (state: CardsTextStateType) => {
        state.createCardsTextError = undefined
      },
      cleanGetCardTextDetails: (state: CardsTextStateType) => {
        state.errorGetCardText = undefined
      },
      cleanEditErrorCardText: (state: CardsTextStateType) => {
        state.errorUpdateCardText = undefined
        state.errorCreateCardText = undefined
      },
      cleanCardTextDetails: (state: CardsTextStateType) => {
        state.cardTextDetails.categoryId = ""
        state.cardTextDetails.createdAt = ""
        state.cardTextDetails.id = ""
        state.cardTextDetails.guessesScore = 0
        state.cardTextDetails.guessesScoreTyping = 0
        state.cardTextDetails.incorrectGuessesScore = 0
        state.cardTextDetails.incorrectGuessesScoreTyping = 0
        state.cardTextDetails.sideOne = ""
        state.cardTextDetails.sideTwo = ""
        state.cardTextDetails.tag = ""
        state.cardTextDetails.updateAt = ""
      },
      cleanFilteredCardsText: (state: CardsTextStateType) => {
        state.filteredCardsText = []
      },
      cleanAllCardsText: (state: CardsTextStateType) => {
        state.allCardsText = []
        state.counts = null
        state.pagesCardText = 0
      },
      cleanCardsTextTags: (state: CardsTextStateType) => {
        state.categoryTags = []
      },
      guessAnswerCorrect: (state: CardsTextStateType) => {
        state.guestOrNot = 1
      },
      guessAnswerNotCorrect: (state: CardsTextStateType) => {
        state.guestOrNot = -1
      },
      guessAnswerClearCorrect: (state: CardsTextStateType) => {
        state.guestOrNot = 0
      },
      cleanAiCreateErrors: (state: CardsTextStateType) => {
        state.errorGenerateCardsText = undefined
      }
      
    },
    extraReducers: (builder) => {
      builder
        .addCase(getCardTextTags.pending, (state: CardsTextStateType) => {
          state.errorCardTextTags = undefined
          state.loadingCardTexTags = true
        })
        .addCase(getCardTextTags.fulfilled, (state: CardsTextStateType, action) => {
          state.loadingCardTexTags = false
          state.categoryTags = action.payload
        })
        .addCase(getCardTextTags.rejected, (state: CardsTextStateType, action) => {
          state.loadingCardTexTags = false
          state.errorCardTextTags = action.error.message
        }),
      builder
        .addCase(getAllCardsTextByCategory.pending, (state: CardsTextStateType) => {
          state.errorGetCardText = undefined
          state.loadingFetchAllCardsText = true
        })
        .addCase(getAllCardsTextByCategory.fulfilled, (state: CardsTextStateType, action) => {
          state.loadingFetchAllCardsText = false
          const allCardsText: CardText[] = []
          action.payload.forEach((card: any) => {
              const cartTextObject = {
                id: card.id,
                sideOne: card.first_side,
                sideTwo: card.second_side,
                categoryId: card.category,
                createdAt: card.created_at,
                updateAt: card.updated_at,
                guessesScore: card.guesses_score,
                incorrectGuessesScore: card.incorrect_guesses_score,
                guessesScoreTyping: card.guesses_score_typing,
                incorrectGuessesScoreTyping: card.incorrect_guesses_score_typing,
                tag: card.tag
              }
              allCardsText.push(cartTextObject)
          })
          state.allCardsText = allCardsText
        })
        .addCase(getAllCardsTextByCategory.rejected, (state: CardsTextStateType, action) => {
          state.loadingFetchAllCardsText = false
          state.errorGetCardText = action.error.message
        }),
      builder
        .addCase(aiGenerateCardTextById.pending, (state: CardsTextStateType) => {
          state.errorGenerateCardsText = undefined
          state.loadingGeneratedCardsText = true
        })
        .addCase(aiGenerateCardTextById.fulfilled, (state: CardsTextStateType, action) => {
          state.loadingGeneratedCardsText = false
          const allCardsText: Pick<CardText, 'id' | 'sideOne' | 'sideTwo' | 'tag' | 'categoryId'>[] = []
          const jsonCards = JSON.parse(action.payload.flashcards.replace(/^json\s*/, ''))
          jsonCards.forEach((card: any) => {
              const cartTextObject = {
                id: card.id,
                sideOne: card.question,
                sideTwo: card.answer,
                categoryId: "generate",
                tag: card.tag
              }
              allCardsText.push(cartTextObject)
          })
          state.generatedCardsText = allCardsText
        })
        .addCase(aiGenerateCardTextById.rejected, (state: CardsTextStateType, action) => {
          state.loadingGeneratedCardsText = false
          state.errorGenerateCardsText = action.error.message
        }),
      builder
        .addCase(aiGenerateCardsWithImages.pending, (state: CardsTextStateType) => {
          state.errorGenerateCardsText = undefined
          state.loadingGeneratedCardsText = true
        })
        .addCase(aiGenerateCardsWithImages.fulfilled, (state: CardsTextStateType, action) => {
          state.loadingGeneratedCardsText = false
          const allCardsText: Pick<CardText, 'id' | 'sideOne' | 'sideTwo' | 'tag' | 'categoryId'>[] = []
          const jsonCards = JSON.parse(action.payload.flashcards.replace(/^json\s*/, ''))
          jsonCards.forEach((card: any) => {
              const cartTextObject = {
                id: card.id,
                sideOne: card.question,
                sideTwo: card.answer,
                categoryId: "generate",
                tag: card.tag
              }
              allCardsText.push(cartTextObject)
          })
          state.generatedCardsText = allCardsText
        })
        .addCase(aiGenerateCardsWithImages.rejected, (state: CardsTextStateType, action) => {
          state.loadingGeneratedCardsText = false
          state.errorGenerateCardsText = action.error.message
        }),
      builder
        .addCase(aiCreateCardTextBulk.pending, (state: CardsTextStateType) => {
          state.errorCreateAiCardsText = undefined
          state.loadingAiCreateCardsText = true
        })
        .addCase(aiCreateCardTextBulk.fulfilled, (state: CardsTextStateType) => {
          state.loadingAiCreateCardsText = false
          state.generatedCardsText = []
        })
        .addCase(aiCreateCardTextBulk.rejected, (state: CardsTextStateType, action) => {
          state.loadingAiCreateCardsText = false
          state.errorCreateAiCardsText = action.error.message
        }),
      builder
        .addCase(getAllCardsTextByCategoryPaginated.pending, (state: CardsTextStateType) => {
          state.errorGetCardText = undefined
          state.loadingFetchAllCardsText = true
        })
        .addCase(getAllCardsTextByCategoryPaginated.fulfilled, (state: CardsTextStateType, action) => {
          state.loadingFetchAllCardsText = false
          state.counts = action.payload.count
          const pages = Math.ceil(action.payload.count / 20)
          state.pagesCardText = action.payload.count / 20 < 0 ? 2 : pages
          const allCardsText: CardText[] = []
          action.payload.results.forEach((card: any) => {
              const cartTextObject = {
                id: card.id,
                sideOne: card.first_side,
                sideTwo: card.second_side,
                categoryId: card.category,
                createdAt: card.created_at,
                updateAt: card.updated_at,
                guessesScore: card.guesses_score,
                incorrectGuessesScore: card.incorrect_guesses_score,
                guessesScoreTyping: card.guesses_score_typing,
                incorrectGuessesScoreTyping: card.incorrect_guesses_score_typing,
                tag: card.tag
              }
              allCardsText.push(cartTextObject)
          })
          state.allCardsText = allCardsText
        })
        .addCase(getAllCardsTextByCategoryPaginated.rejected, (state: CardsTextStateType, action) => {
          state.loadingFetchAllCardsText = false
          state.errorGetCardText = action.error.message
        }),
      builder
        .addCase(getAllCardsTextByFilters.pending, (state: CardsTextStateType) => {
          state.errorGetCardText = undefined
          state.loadingFetchAllCardsText = true
        })
        .addCase(getAllCardsTextByFilters.fulfilled, (state: CardsTextStateType, action) => {
          state.loadingFetchAllCardsText = false
          const allCardsText: CardText[] = []
          action.payload.forEach((card: any) => {
              const cartTextObject = {
                id: card.id,
                sideOne: card.first_side,
                sideTwo: card.second_side,
                categoryId: card.category,
                createdAt: card.created_at,
                updateAt: card.updated_at,
                guessesScore: card.guesses_score,
                incorrectGuessesScore: card.incorrect_guesses_score,
                guessesScoreTyping: card.guesses_score_typing,
                incorrectGuessesScoreTyping: card.incorrect_guesses_score_typing,
                tag: card.tag
              }
              allCardsText.push(cartTextObject)
          })
          state.filteredCardsText = allCardsText;
          if (state.usedIds.length >= state.filteredCardsText.length) {
            state.usedIds = []
          }
          let newId;
          do {
            newId = Math.floor(Math.random() * state.filteredCardsText.length);
          } while (state.usedIds.includes(newId));
          state.usedIds.push(newId);
          state.randomId = newId;
        })
        .addCase(getAllCardsTextByFilters.rejected, (state: CardsTextStateType, action) => {
          state.loadingFetchAllCardsText = false
          state.errorGetCardText = action.error.message
        }),
      builder
        .addCase(createNewCardText.pending, (state: CardsTextStateType) => {
          state.errorCreateCardText = undefined
          state.errorGetCardText = undefined
          state.errorUpdateCardText = undefined
          state.loadingCreateCardText = true
        })
        .addCase(createNewCardText.fulfilled, (state: CardsTextStateType) => {
          state.loadingCreateCardText = false
        })
        .addCase(createNewCardText.rejected, (state: CardsTextStateType, action) => {
          state.loadingCreateCardText = false
          state.errorCreateCardText = action.error.message
        }),
      builder
        .addCase(createNewCardsTest.pending, (state: CardsTextStateType) => {
          state.createCardsTextError = undefined
          state.errorGetCardText = undefined
          state.errorUpdateCardText = undefined
          state.createCardsTextLoading = true
        })
        .addCase(createNewCardsTest.fulfilled, (state: CardsTextStateType) => {
          state.createCardsTextLoading = false
        })
        .addCase(createNewCardsTest.rejected, (state: CardsTextStateType, action) => {
          state.createCardsTextLoading = false
          state.createCardsTextError = action.error.message
        }),
      builder
        .addCase(deleteCardTextById.pending, (state: CardsTextStateType) => {
          state.errorDeleteCardText = ""
          state.loadingDeleteCardText = true
        })
        .addCase(deleteCardTextById.fulfilled, (state: CardsTextStateType) => {
          state.loadingDeleteCardText = false
        })
        .addCase(deleteCardTextById.rejected, (state: CardsTextStateType, action) => {
          state.loadingDeleteCardText = false
          state.errorDeleteCardText = action.error.message
        }),
      builder
        .addCase(getCardTextById.pending, (state: CardsTextStateType) => {
          state.errorGetCardText = undefined
          state.loadingGetCardText = true
        })
        .addCase(getCardTextById.fulfilled, (state: CardsTextStateType, action) => {
          state.loadingGetCardText = false
          state.cardTextDetails.id = action.payload.id
          state.cardTextDetails.sideOne = action.payload.first_side
          state.cardTextDetails.sideTwo = action.payload.second_side
          state.cardTextDetails.categoryId = action.payload.category
          state.cardTextDetails.tag = action.payload.tag
          state.cardTextDetails.createdAt = action.payload.created_at
          state.cardTextDetails.updateAt = action.payload.updated_at
          state.cardTextDetails.guessesScore = action.payload.guesses_score
          state.cardTextDetails.incorrectGuessesScore = action.payload.incorrect_guesses_score
          state.cardTextDetails.guessesScoreTyping = action.payload.guesses_score_typing
          state.cardTextDetails.incorrectGuessesScore = action.payload.incorrect_guesses_score_typing
        })
        .addCase(getCardTextById.rejected, (state: CardsTextStateType, action) => {
          state.loadingGetCardText = false
          state.errorGetCardText = action.error.message
        })
      builder
        .addCase(editCardTextById.pending, (state: CardsTextStateType) => {
          state.errorUpdateCardText = undefined
          state.loadingUpdateCardText = true
        })
        .addCase(editCardTextById.fulfilled, (state: CardsTextStateType) => {
          state.loadingUpdateCardText = false
        })
        .addCase(editCardTextById.rejected, (state: CardsTextStateType, action) => {
          state.loadingUpdateCardText = false
          state.errorUpdateCardText = action.error.message
        })
      builder
        .addCase(guestCardText.pending, (state: CardsTextStateType) => {
          state.errorUpdateCardText = undefined
          state.loadingUpdateCardText = true
        })
        .addCase(guestCardText.fulfilled, (state: CardsTextStateType) => {
          state.loadingUpdateCardText = false
        })
        .addCase(guestCardText.rejected, (state: CardsTextStateType, action) => {
          state.loadingUpdateCardText = false
          state.errorUpdateCardText = action.error.message
        })
      builder
        .addCase(notGuestCardText.pending, (state: CardsTextStateType) => {
          state.errorUpdateCardText = undefined
          state.loadingUpdateCardText = true
        })
        .addCase(notGuestCardText.fulfilled, (state: CardsTextStateType) => {
          state.loadingUpdateCardText = false
        })
        .addCase(notGuestCardText.rejected, (state: CardsTextStateType, action) => {
          state.loadingUpdateCardText = false
          state.errorUpdateCardText = action.error.message
        })
      builder
        .addCase(guestTypingCardText.pending, (state: CardsTextStateType) => {
          state.errorUpdateCardText = undefined
          state.loadingUpdateCardText = true
        })
        .addCase(guestTypingCardText.fulfilled, (state: CardsTextStateType) => {
          state.loadingUpdateCardText = false
        })
        .addCase(guestTypingCardText.rejected, (state: CardsTextStateType, action) => {
          state.loadingUpdateCardText = false
          state.errorUpdateCardText = action.error.message
        })
      builder
        .addCase(notGuestTypingCardText.pending, (state: CardsTextStateType) => {
          state.errorUpdateCardText = undefined
          state.loadingUpdateCardText = true
        })
        .addCase(notGuestTypingCardText.fulfilled, (state: CardsTextStateType) => {
          state.loadingUpdateCardText = false
        })
        .addCase(notGuestTypingCardText.rejected, (state: CardsTextStateType, action) => {
          state.loadingUpdateCardText = false
          state.errorUpdateCardText = action.error.message
        })
    }
})

export const actions = cardsTextSlice.actions;

export const allCardsTextSelector = (state: RootState) => state[cardsTextSlice.name];
