import { BASE_URL } from "../common/constants";
import axios, { AxiosResponse } from "axios";
import { withTokenCheck } from './userAxiosServices'
import { aiGenerateCardText, aiGenerateCardWithImages } from "../common/card-model";

const generateCardsText = async ({ topic, sideOne, sideTwo, level, audienceAge, language, cardNumber }: aiGenerateCardText) => {
  const body = {
    topic: topic,
    sideOne: sideOne,
    sideTwo: sideTwo,
    level: level,
    audienceAge: audienceAge,
    language: language,
    cardNumber: cardNumber
  }
  const response = await axios.post(BASE_URL + `card/generate-flashcards/`, body, {

    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const generateCardsTextService = withTokenCheck(generateCardsText)

const generateCardsWithImages = async ({ firstImage, secondImage, thirdImage, imageLanguage, cardQuantity, language }: aiGenerateCardWithImages) => {
  let response
  if (firstImage && language) {
    const formData = new FormData();
    formData.append('first_image', firstImage);
    formData.append('image_language', imageLanguage);
    formData.append('card_quantity', cardQuantity);
    formData.append('language', language)
    if(secondImage) {
      formData.append('second_image', secondImage);
    }
    if(thirdImage) {
      formData.append('third_image', thirdImage);
    }
    try {
      response = axios.post(BASE_URL + 'card/generate-flashcards-from-images/', formData, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`,
          'content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  if (!response) {
    // If response is still undefined, create a placeholder response with required properties
    response = {
      data: undefined,
      status: 500, // You may want to adjust the status code based on your needs
      statusText: 'Internal Server Error',
      headers: {},
      config: {},
    };
  }
  return response as AxiosResponse<any, any>;
}

export const generateCardsWithImagesService = withTokenCheck(generateCardsWithImages)

export interface aiCreateCardTextProps {
  categoryName: string;
  flashcards: {
    sideOne: string;
    sideTwo: string;
    tag: string;
  }[];
}

const aiCreateCardText = async ({ categoryName, flashcards }: aiCreateCardTextProps) => {
  const body = {
    category_name: categoryName,
    flashcards: flashcards
  }
  const response = await axios.post(BASE_URL + `card/create-ai-flashcards/`, body, {

    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    }
  });
  return response;
}

export const aiCreateCardTextService = withTokenCheck(aiCreateCardText)